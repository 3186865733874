<template>
  <v-autocomplete
    :label="label"
    :items="customerList"
    v-model="localCustomer"
    :item-text="itemTextCustomers"
    return-object
    dense
    clearable
    autofocus
    outlined
    v-disabled-icon-focus
    :rules="effectiveRules"
    :loading="loading"
    :search-input.sync="customerSearchQuery"
    hide-no-data
    cache-items
    :filter="effectiveFilter"
    ref="customerAutocomplete"
    :erro-messages="erroMessage"
  />
</template>

<script>
// Models
import CustomerModel from '@/model/customer-model.js';
import CustomerFiltersModel from "@/model/customer/customer-filters-model";

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";
import mixinAutoCompleteFilters from "@/mixin/mixin-autocomplete-filters";

// Constants
import { CUSTOMER_WORKFLOW } from "../../../../common/workflows/customersWorkflow";

// Apis
import CustomerApi from "@/api/customer/customer-api";

export default {
  name: 'CustomerSearch',

  model: { 
    prop: 'customer',
    event: 'onChange',
  },

  props: {
    customer: { type: CustomerModel, default: () => {} },
    label: { type: String, default: 'Cliente / CPF / CNPJ' },
    rules: { type: Array, default: null },
    filter: { type: Function, default: null },
    erroMessage: { type: String, default: '' }
  },

  mixins: [
    mixinValidationRules,
    mixinAutoCompleteFilters
  ],

  inject: ['appData'],

  data() {
    return {
      customerList: [],
      loading: false,
      customerSearchQuery: '',
      customerSearchTimer: undefined,
      customerApi: new CustomerApi(this.appData.currentUser),
      defaultRules: [this.validationIsRequiredAutocompleteId],
      defaultFilter: this.caseInsensitiveAccentsInsensitiveIgnoreDotsDashes
    };
  },
  
  computed: {
    localCustomer: {
      get() {
        return this.customer;
      },
      set(valor) {
        this.$emit('onChange', valor);
      }
    },

    itemTextCustomers() {
      return (item) => !item.name ? `${item.companyName} - ${item.cpfCnpj}` : `${item.name} - ${item.cpfCnpj}`;
    },

    effectiveRules() {
      return this.rules || this.defaultRules;
    },

    effectiveFilter() {
      return this.filter || this.defaultFilter;
    },
  },

  watch: {
    customerSearchQuery() {
      clearTimeout(this.customerSearchTimer);

      if (this.customerSearchQuery === this.itemTextCustomers(this.customer)) return;

      if ((this.customerSearchQuery?.length || 0) < 3) {
        this.customerList = [];

        return;
      }


      this.customerSearchTimer = setTimeout(() => {
        this.loading = true;
        this.getCustomersList()
          .then(() => { 
            this.loading = false;
          });
      }, 500);
    }
  },

  methods: {
    getCustomersList() {
      let filters = new CustomerFiltersModel({
        registerStatusList: [CUSTOMER_WORKFLOW.CADASTRO_APROVADO.id],
        searchText: this.customerSearchQuery,
      });

      return this.customerApi
        .findAll(filters)
        .then((response) => {
          this.customerList = response.data.map((c) => new CustomerModel(c));
        })
        .catch((error) => {
          this.sendMessage(
            error?.response?.data?.mensagem || error,
            "error"
          );
        });
    },
  },
};
</script>