<template>
  <v-text-field
    :id="id ? id : undefined"
    :readonly="readonly"
    :label="label"
    :maxlength="maxlength"
    :rules="[...rules, ...additionalRules]"
    v-model.lazy="value"
    v-money="formatMoney"
    @change="emitChanges"
    @keydown="verifySignal"
    @keypress="blockByValue"
    :autofocus="autofocus"
    ref="textField"
    :outlined="outlined"
    :dense="dense"
    :hint="hint"
    :persistent-hint="persistentHint"
    :validate-on-blur="validateOnBlur"
    :disabled="disabled"
  />
</template>

<script>
export default {
  model: {
    prop: "inputValue",
    event: "change",
  },
  props: {
    autofocus: { type: Boolean, default: false },
    inputValue: {
      type: [Number,String],
      default: 0,
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
    allowNegative: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    maxValue: {
      type: Number,
      required: false,
    },
    maxlength: {
      type: Number,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    prefix: {
      type: String,
      default: "R$ ",
    },
    suffix: { 
      type: String,
      required: false,
      default: ""
    },
    hint: { 
      type: String,
      required: false,
      default: ""
    },
    persistentHint: {
      type: Boolean,
      required: false,
      default: false
    },
    min: {
      type: [Number, String],
      required: false,
    },
    max: {
      type: [Number, String],
      required: false,
    },
    precision: {
      type: [Number, String],
      required: false,
      default: 2,
    },
    validateOnBlur: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    value: 0,
    additionalRules: [],
  }),

  watch: {
    inputValue(newValue) {
      this.setNewValueManually(newValue);
    },

    autofocus(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.textField.focus();
          this.onFocus();
        });
      }
    },
  },

  mounted() {
    this.setNewValueManually(this.inputValue);
    this.setAdditionalRules();
    // if not present, the component initializes with validation errors
    this.$refs.textField.resetValidation();
  },

  beforeUpdate(){
    this.setAdditionalRules();
  },

  computed: {
    formatMoney() {
      return {
        decimal: ",",
        thousands: ".",
        prefix: this.prefix,
        precision: this.precision,
        suffix: this.suffix
      };
    },
  },

  methods: {
    onFocus() {
      this.$emit("onFocus");
    },

    // Hack to force mask to render and dont remove zero and integer values.
    // When the mask receives the numbers zero to the right of the comma, the component broke
    setNewValueManually(value) {
      this.$refs.textField.$el.getElementsByTagName(
        "input"
      )[0].value = this.value = parseFloat(value).toFixed(this.precision);
    },

    // This method will be call only when the v-model change
    // Emit changes for each change in value(watch) generates errors if you pass numbers
    // Ending with 0 (Example: 100 is show 0,01)

    emitChanges() {
      this.$emit("change", parseFloat(this.removeMask(this.value)));
    },

    // Dont allow negative values
    verifySignal(e) {
      if (!this.allowNegative && e.key && e.key === "-") e.preventDefault();
    },

    // TODO: It's not good. Create better validation
    blockByValue(e) {
      if (!this.maxValue) return;

      let inputValue = e.key;
      let value =
        this.maxValue - (this.removeMask(e.target.value) + Number(inputValue));

      if (value <= 0) {
        e.preventDefault();
      }
    },

    removeMask(value) {
      if (typeof value == "string") {
        return parseFloat(
          value
            .replace(this.prefix, "")
            .replace(/\./g, "")
            .replace(",", ".")
            .replace(this.suffix, "")
        );
      } else if (typeof value == "number") {
        return value;
      }

      return 0;
    },

    setAdditionalRules(){
      this.additionalRules = [];

      let min = parseFloat(this.min);

      if (min) {
        this.additionalRules.push(
          (value) => {
            let valueNoMask = this.removeMask(value);

            return valueNoMask >= min || `O valor mínimo permitido é ${this.prefix + min + this.suffix}`;
          }
        );
      }

      let max = parseFloat(this.max);

      if (max) {
        this.additionalRules.push(
          (value) => {
            let valueNoMask = this.removeMask(value);

            return valueNoMask <= max || `O valor máximo permitido é ${this.prefix + max + this.suffix}`;
          }
        );
      }


    },
  },
};
</script>
