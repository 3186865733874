<template>
  <v-row
    no-gutters
    class="box pb-0"
  >
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="12">
          <h1 class="titulo">Lucro / Remessas internacionais</h1>
          <v-divider />
        </v-col>
      </v-row>

      <v-row
        no-gutters
        justify="end"
      >
        <v-col class="d-flex align-end">
          <v-btn
            class="btn-primary float-left"
            text
            @click="openModalUpdateComissions"
            v-administrative-access
          >
            Atualizar comissões em massa
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          md="3"
          sm="4"
          class="pa-0 pr-3"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          />
        </v-col>

        <v-col class="shrink d-flex align-end pl-2">
          <operation-filter-drawer
            v-model="filters"
            :bank-list="bankList"
            :currency-list="currencyList"
            :status-list="[statusId]"
            :exchange-type-id="exchangeTypeId"
            filter-this-month
            @filter="getOperations"
          />
        </v-col>
      </v-row>

      <v-row
        no-gutters
        class="pt-2"
      >
        <v-col
          v-if="!operations.length"
          cols="12"
        >
          <EmptyTable
            title="Nenhum registro encontrado"
            icon="far fa-hand-holding-usd"
            subtitle="Não há operações de Câmbio Remessa para o período selecionado."
          />
        </v-col>

        <v-col
          v-else
          cols="12"
        >
          <v-card class="pt-6 mb-2 card-cambio">
            <v-row class="pb-5 pl-6">
              <v-row class="pb-5 pl-6">
                <v-row
                  class="d-flex pt-5"
                  justify="space-around"
                  style="width: 100%;"
                >
                  <v-col
                    v-for="(item, index) in firstRowTotals"
                    :key="index"
                    cols="12"
                    sm="12"
                    md="12"
                    lg="2"
                    xl="2"
                    class="pr-2 pt-3 pb-3"
                  >
                    <v-row class="flex-nowrap">
                      <v-col
                        cols="3"
                        class="d-flex justify-center"
                      >
                        <v-avatar
                          tile
                          class="rounded-avatar"
                          color="#1E1E1E"
                          v-show="$vuetify.theme.dark"
                        >
                          <v-icon
                            dark
                            color="primary"
                          >
                            {{ item.icon }}
                          </v-icon>
                        </v-avatar>
                        <v-avatar
                          tile
                          class="rounded-avatar"
                          color="#eceff1"
                          v-show="!$vuetify.theme.dark"
                        >
                          <v-icon
                            dark
                            color="primary"
                          >
                            {{ item.icon }}
                          </v-icon>
                        </v-avatar>
                      </v-col>
                      <v-col
                        cols="9"
                        class="pt-0 pl-0 pr-0"
                      >
                        <v-card-text class="pt-2 pb-2 pr-0">
                          <h1 class="title-sum">{{ item.title }}</h1>
                          <span class="value-sum">{{ item.value }}</span>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row
                  class="d-flex flex-wrap"
                  justify="space-around"
                  style="width: 100%;"
                >
                  <v-col
                    v-for="(item, index) in secondRowTotals"
                    :key="index"
                    cols="12"
                    sm="12"
                    md="12"
                    lg="2"
                    xl="2"
                    class="pr-2 pt-3 pb-3"
                  >
                    <v-row class="flex-nowrap">
                      <v-col
                        cols="3"
                        class="d-flex justify-center"
                      >
                        <v-avatar
                          tile
                          class="rounded-avatar"
                          color="#1E1E1E"
                          v-show="$vuetify.theme.dark"
                        >
                          <v-icon
                            dark
                            color="primary"
                          >
                            {{ item.icon }}
                          </v-icon>
                        </v-avatar>
                        <v-avatar
                          tile
                          class="rounded-avatar"
                          color="#eceff1"
                          v-show="!$vuetify.theme.dark"
                        >
                          <v-icon
                            dark
                            color="primary"
                          >
                            {{ item.icon }}
                          </v-icon>
                        </v-avatar>
                      </v-col>
                      <v-col
                        cols="9"
                        class="pt-0 pl-0 pr-0"
                      >
                        <v-card-text class="pt-2 pb-2 pr-0">
                          <h1 class="title-sum">{{ item.title }}</h1>
                          <span class="value-sum">{{ item.value }}</span>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-row>
            </v-row>
          </v-card>


          <v-card
            class="card-cambio"
            v-if="!$vuetify.breakpoint.xs"
          >
            <v-data-table
              :expanded.sync="expanded"
              show-expand
              :headers="columns"
              :items="operations"
              :search="search"
              sort-by="effectiveOperationDate"
              sort-desc
              height="calc(100dvh - 470px)"
              :items-per-page="paginationDefault"
              :custom-filter="caseInsensitiveAccentsInsensitive"
              :custom-sort="customSort"
              @click:row="clickRow"
              fixed-header
            >
              <template #[`item.customer.customerNameOrCompanyName`]="{ item }">
                <span v-if="item.customer.isPJ()">{{ item.customer.companyName }}
                  <span>
                    <InfoTooltip
                      icon-size="1x"
                      :label="'Nome fantasia: ' + item.customer.fantasyName"
                    />
                  </span>
                </span>
                <span v-else>{{ item.customer.name }}</span>
              </template>

              <template #[`item.effectiveOperationDate`]="{ item }">
                <span v-administrative-access>
                  <v-edit-dialog
                    save-text="Salvar"
                    cancel-text="Cancelar"
                    :return-value.sync="item.effectiveOperationDate"
                    large
                    @save="saveUpdatedOperation(item)"
                  >
                    <span>
                      {{ item.effectiveOperationDate | date }}
                    </span>
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <i
                          class="far fa-pencil icon-button"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                    <template #input>
                      <Datepicker
                        v-model="item.effectiveOperationDate"
                        label="Data"
                        autofocus
                        v-disabled-icon-focus
                        dense
                        class="pt-2"
                      />
                    </template>
                  </v-edit-dialog>
                </span>

                <span v-corban-access>
                  {{ item.effectiveOperationDate | date }}
                </span>
              </template>
              <template #[`item.amount`]="{ item }">
                <span>{{ item.amount | formatCurrency(item.currency.code) }}</span>
              </template>
              <template #[`item.customerRate`]="{ item }">
                <span>{{ item.customerRate | formatCurrency('BRL', 5) }}</span>
              </template>

              <template #[`item.bankRate`]="{ item }">
                <span v-administrative-access>
                  <v-edit-dialog
                    save-text="Salvar"
                    cancel-text="Cancelar"
                    :return-value.sync="item.bankRate"
                    large
                    @save="saveUpdatedOperation(item)"
                  >
                    <span>{{ item.bankRate | formatCurrency('BRL', 5) }}</span>
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <i
                          class="far fa-pencil"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                    <template #input>
                      <MoneyField
                        label="Taxa do Banco"
                        prefix="R$ "
                        v-model="item.bankRate"
                        :precision="6"
                        autofocus
                        class="pt-2"
                      />
                    </template>
                  </v-edit-dialog>
                </span>

                <span v-corban-access>
                  {{ item.bankRate | formatCurrency('BRL', 5) }}
                </span>
              </template>

              <template #[`item.administrative.grossProfit`]="{ item }">
                <span>{{ item.administrative.grossProfit | formatCurrency }}
                </span>
              </template>
              <template #[`item.administrative.agentValue`]="{ item }">
                <span>{{ item.administrative.agentValue | formatCurrency }}</span>
              </template>
              <template #[`item.administrative.cambioHojeComissionValue`]="{ item }">
                <span>{{ item.administrative.cambioHojeComissionValue | formatCurrency }}</span>
              </template>
              <template #[`item.administrative.indicatorValue`]="{ item }">
                <span>{{ item.administrative.indicatorValue | formatCurrency }}</span>
              </template>

              <template #expanded-item="{ item }">
                <td :colspan="columns.length + 1">
                  <v-card class="box card-cambio my-5">
                    <v-row>
                      <v-col
                        cols="12"
                        md="1"
                      >
                        <span class="expand-title">Nº da OP</span>
                        <p class="expand-value">{{ item.id }}</p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <span class="expand-title">Tipo de operação</span>
                        <p>
                          <v-chip
                            v-if="item.exchangeBuy"
                            small
                            label
                            color="teal lighten-4"
                          >
                            Compra
                          </v-chip>
                          <v-chip
                            v-else
                            small
                            label
                            color="red lighten-4"
                          >
                            Venda
                          </v-chip>
                        </p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="1"
                      >
                        <span class="expand-title">Pis/Cofins</span>
                        <p class="expand-value">
                          {{ item.administrative.pisCofins | formatCurrency }}
                        </p>
                      </v-col>

                      <v-col
                        cols="12"
                        md="2"
                      >
                        <span class="expand-title">Banco</span>
                        <p class="expand-value">
                          {{ item.bank.fantasyName }}
                          <InfoTooltip
                            icon-size="1x"
                            :label="'Comissão do Banco: ' + item.administrative.comission + '%'"
                          />
                        </p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <span class="expand-title">Corban</span>
                        <p class="expand-value">
                          {{ item.administrative.agentData.name }}
                          <InfoTooltip
                            icon-size="1x"
                            :label="'Comissão do Corban: ' + item.administrative.agent + '%'"
                          />
                        </p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        v-if="item.administrative.indicatorData?.id"
                      >
                        <span class="expand-title">Corban Indicador</span>
                        <p class="expand-value">
                          {{ item.administrative.indicatorData.name }}
                          <InfoTooltip
                            icon-size="1x"
                            :label="
                              'Comissão do Corban Indicador: ' + item.administrative.indicator + '%'
                            "
                          />
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-card>
          <div v-else>
            <div
              v-for="item in operations"
              :key="item"
              class="pb-2"
            >
              <v-card class="card-cambio">
                <v-row>
                  <v-col
                    cols="7"
                    class="pt-6 pl-6 pb-0"
                  >
                    <strong v-if="item.customer.isPJ()">Nome fantasia:<br></strong>
                    <strong v-if="!item.customer.isPJ()">Nome: <br></strong>
                    <span v-if="item.customer.isPJ()">{{ item.customer.companyName }}
                      <span>
                        <InfoTooltip
                          icon-size="1x"
                          :label="'Nome fantasia: ' + item.customer.fantasyName"
                        />
                      </span>
                    </span>
                    <span v-else>{{ item.customer.name }}</span>
                  </v-col>
                  <v-col
                    cols="5"
                    class="pb-0 pt-6"
                  >
                    <div class="data-colum">
                      <strong>Data:</strong>
                    </div>
                    <v-edit-dialog
                      save-text="Salvar"
                      cancel-text="Cancelar"
                      :return-value.sync="item.effectiveOperationDate"
                      large
                      @save="saveUpdatedOperation(item)"
                    >
                      <span>
                        {{ item.effectiveOperationDate | date }}
                      </span>
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <i
                            class="far fa-pencil icon-button"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <template #input>
                        <Datepicker
                          v-model="item.effectiveOperationDate"
                          label="Data"
                          autofocus
                          v-disabled-icon-focus
                          dense
                          class="pt-2"
                        />
                      </template>
                    </v-edit-dialog>
                  </v-col>
                  <v-col
                    cols="7"
                    class="pl-6"
                  >
                    <strong>Valor ME:</strong><br>
                    <span>{{ item.amount | formatCurrency(item.currency.code) }}</span>
                  </v-col>
                  <v-col cols="5">
                    <strong>Taxa do cliente:</strong><br>
                    <span>{{ item.customerRate | formatCurrency('BRL', 5) }}</span>
                  </v-col>
                  <v-col
                    cols="7"
                    class="pl-6"
                  >
                    <strong>Lucro bruto:</strong><br>
                    <span>{{ item.administrative.grossProfit | formatCurrency }}</span>
                  </v-col>
                  <v-col
                    cols="5"
                    class="pt-0 pt-3"
                  >
                    <div class="data-colum">
                      <strong>Taxa do banco:</strong>
                    </div>
                    <v-edit-dialog
                      save-text="Salvar"
                      cancel-text="Cancelar"
                      :return-value.sync="item.bankRate"
                      large
                      @save="saveUpdatedOperation(item)"
                    >
                      <span>{{ item.bankRate | formatCurrency('BRL', 5) }}</span>
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <i
                            class="far fa-pencil"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <template #input>
                        <MoneyField
                          label="Taxa do Banco"
                          prefix="R$ "
                          v-model="item.bankRate"
                          :precision="6"
                          autofocus
                          class="pt-2"
                        />
                      </template>
                    </v-edit-dialog>
                  </v-col>
                  <v-col
                    cols="7"
                    class="pt-0 pl-6 pb-6"
                  >
                    <strong>Lucro Corban:</strong><br>
                    <span>{{ item.administrative.agentValue | formatCurrency }}</span>
                  </v-col>
                  <v-col
                    cols="5"
                    class="pt-0 pb-6"
                  >
                    <strong>Lucro indicador:</strong><br>
                    <span>{{ item.administrative.indicatorValue | formatCurrency }}</span>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <v-dialog
            persistent
            max-width="600px"
            v-model="modalUpdateComissions"
          >
            <v-card>
              <v-card-title>
                <h1 class="titulo-modal">Atualizar comissões em massa</h1>
              </v-card-title>
              <v-divider />
              <v-card-text class="pt-5">
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-form
                      id="add-operation"
                      ref="form"
                    >
                      <v-row>
                        <v-col
                          class="py-0"
                          cols="12"
                          md="12"
                        >
                          <MoneyField
                            v-model="updatedComission"
                            outlined
                            dense
                            label="Comissão(%)"
                            suffix="%"
                            prefix=""
                            :readonly="false"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-col
                  cols="12"
                  md="12"
                  class="text-right"
                >
                  <v-btn
                    text
                    class="btn-primary mr-2"
                    @click="massUpdateComissions"
                  >
                    Atualizar
                  </v-btn>
                  <v-btn
                    text
                    class="btn-tertiary"
                    @click="modalUpdateComissions = false"
                  >
                    Cancelar
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// Api
import OperationApi from '@/api/operation/operation-api';
import CurrencyApi from '@/api/spread/currency-api';
import ExchangeBankApi from '@/api/exchangeBank/exchange-bank-api';

// Models
import OperationModel from '@/model/operation-model';
import OperationFiltersModel from '@/model/operation-filters-model';
import CurrencyModel from '@/model/currency-model';
import ExchangeBankModel from '@/model/exchange-bank-model';

// Mixins
import mixinMessage from '@/mixin/mixin-message';
import mixinDataTableFilters from '@/mixin/mixin-data-table-filters';
import mixinExpandedDataTable from '@/mixin/mixin-data-table-expanded';

// Utils
import dateUtils from '../../../../common/utils/date';
import numberUtils from '../../../../common/utils/number';
import { customSortDataTable } from "@/utils/dataTable";

// Components
import Datepicker from '@/components/comum/Datepicker.vue';
import EmptyTable from '@/components/comum/EmptyTable.vue';
import MoneyField from '@/components/comum/MoneyField.vue';
import InfoTooltip from '@/components/comum/InfoTooltip';
import OperationFilterDrawer from '@/components/comum/OperationFilterDrawer.vue';


// Constants
import { OPERATION_WORKFLOW } from '../../../../common/workflows/operationWorkflow';
import { OPERATION_TYPE } from '../../../../common/constants/generic/types';
import { PAGINATION_DEFAULT } from '../../constants/general-constants';

export default {
  name: 'RemittanceOperationManagementReport',
  mixins: [mixinMessage, mixinDataTableFilters, mixinExpandedDataTable],
  components: {
    Datepicker,
    EmptyTable,
    MoneyField,
    InfoTooltip,
    OperationFilterDrawer
  },
  inject: ['appData'],
  data() {
    return {
      search: '',
      operations: [],
      columns: [
        { text: 'Data', value: 'effectiveOperationDate', align: 'left', sortable: true, width: '180px' },
        { text: 'Cliente / Razão social', value: 'customer.customerNameOrCompanyName', align: 'left', sortable: true, width: '500px' },
        { text: 'Valor M.E', value: 'amount', align: 'left', sortable: true },
        { text: 'Taxa do cliente', value: 'customerRate', align: 'left', sortable: true, width: '150px' },
        { text: 'Taxa do banco', value: 'bankRate', align: 'left', sortable: true, width: '150px' },
        { text: 'Lucro bruto', value: 'administrative.grossProfit', align: 'left', sortable: true, width: '200px' },
        { text: 'Lucro corban master', value: 'administrative.cambioHojeComissionValue', align: 'left', sortable: true, width: '200px' },
        { text: 'Lucro corban', value: 'administrative.agentValue', align: 'left', sortable: true, width: '200px' },
        {
          text: 'Lucro Indicador',
          value: 'administrative.indicatorValue',
          align: 'left',
          sortable: true,
          width: '200px'
        }
      ],
      firstRowTotals: [],
      secondRowTotals: [],
      filters: new OperationFiltersModel(),
      currencyList: [],
      bankList: [],
      updatedComission: 0,
      modalUpdateComissions: false,
      statusId: OPERATION_WORKFLOW.LIQUIDADA.id,
      exchangeTypeId: OPERATION_TYPE.REMMITANCE.id,
      paginationDefault: PAGINATION_DEFAULT,
      currencyApi: new CurrencyApi(this.appData.currentUser),
      exchangeBankApi: new ExchangeBankApi(this.appData.currentUser),
      operationApi: new OperationApi(this.appData.currentUser),
    };
  },
  watch: {
    operations: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.calculateTotals();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getCurrencies();
    this.getBankList();
    this.getOperations();
  },
  computed: {
    sumSubtotal() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.operationSubTotal);
      }, 0);
    },
    sumGrossProfit() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.grossProfit);
      }, 0);
    },
    sumNetProfit() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.netProfit);
      }, 0);
    },
    sumAgentValue() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.agentValue);
      }, 0);
    },
    sumIndicatorValue() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.indicatorValue);
      }, 0);
    },
    sumCambioHojeComissionValue() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.cambioHojeComissionValue);
      }, 0);
    },
    sumPisCofins() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.pisCofins);
      }, 0);
    },
    sumCashBackContractValue() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.cashbackContractValue);
      }, 0);
    },
    sumBankComissionValue() {
      return this.operations.reduce((acc, operation) => {
        return acc + parseFloat(operation.administrative.bankComissionValue);
      }, 0);
    }
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
    formatCurrency(value, code, precison) {
      code = code || 'BRL';

      return numberUtils.formatCurrency(code, value, precison);
    }
  },
  methods: {
    getOperations() {
      this.$eventBus.$emit('show-loading', true);

      this.operationApi
        .find(this.filters)
        .then((response) => {
          this.operations = response.data.map((o) => {
            let operation = new OperationModel(o);

            return operation;
          });
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
          }

          this.operations = [];
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    },

    getCurrencies() {
      if (this.currencyList[0]) {
        return;
      }

      this.currencyApi
        .findAll()
        .then((response) => {
          this.currencyList = response.data.map((c) => new CurrencyModel(c));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },
    getBankList() {
      this.exchangeBankApi
        .findAll()
        .then((response) => {
          this.bankList = response.data.map((b) => new ExchangeBankModel(b));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
          this.loading = false;
        });
    },

    saveUpdatedOperation(operation) {
      this.operationApi
        .update(operation)
        .then((response) => {
          let updatedOperation = new OperationModel(response.data);
          let index = this.operations.findIndex((o) => o.id == updatedOperation.id);
          this.operations.splice(index, 1, updatedOperation);
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    openModalUpdateComissions() {
      this.updatedComission = 0;
      this.modalUpdateComissions = true;
    },
    massUpdateComissions() {
      this.$eventBus.$emit('show-loading', true);

      this.operationApi
        .massUpdateComissions(this.filters, this.updatedComission)
        .then(() => {
          this.getOperations();
          this.modalUpdateComissions = false;
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    },

    customSort(items, sortBy, sortDesc) {
      const customFields = [];

      return customSortDataTable(items, sortBy, sortDesc, customFields);
    },

    calculateTotals() {
      this.firstRowTotals = [
        {
          title: 'Operações',
          value: this.operations.length,
          icon: 'mdi-file-document',
        },
        {
          title: 'Total operado',
          value: numberUtils.formatCurrency('BRL', this.sumSubtotal),
          icon: 'mdi-cash',
        },
        {
          title: 'Lucro bruto',
          value: numberUtils.formatCurrency('BRL', this.sumGrossProfit),
          icon: 'mdi-trending-up',
        },
        {
          title: 'PIS/COFINS',
          value: numberUtils.formatCurrency('BRL', this.sumPisCofins),
          icon: 'mdi-percent',
        },
        {
          title: 'Lucro liquido',
          value: numberUtils.formatCurrency('BRL', this.sumNetProfit),
          icon: 'mdi-cash-multiple',
        }
      ];

      this.secondRowTotals = [
        {
          title: 'Corban master',
          value: numberUtils.formatCurrency('BRL', this.sumCambioHojeComissionValue),
          icon: 'mdi-currency-usd',
        },
        {
          title: 'Corban',
          value: numberUtils.formatCurrency('BRL', this.sumAgentValue),
          icon: 'mdi-bank',
        },
        {
          title: 'Corban indicador',
          value: numberUtils.formatCurrency('BRL', this.sumIndicatorValue),
          icon: 'mdi-chart-bar',
        },
        {
          title: 'Lucro banco',
          value: numberUtils.formatCurrency('BRL', this.sumBankComissionValue),
          icon: 'mdi-currency-usd',
        },
        {
          title: 'Cashback de tarifa',
          value: numberUtils.formatCurrency('BRL', this.sumCashBackContractValue),
          icon: 'mdi-cash-refund',
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.title-sum {
  font-size: 0.8rem;
  font-weight: 500;
}

.rounded-avatar {
  border-radius: 8px !important;
}

.value-sum {
  color: var(--v-primary-base);
  font-size: 1.5rem;
  font-weight: 300;
}

i.far.fa-pencil {
  color: var(--v-primary-base);
}

.data-colum {
  margin-bottom: -15px;
}
</style>
