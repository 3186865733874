<template>
  <div class="ma-5">
    <v-row
      no-gutters
      justify="end"
    >
      <v-col class="shrink">
        <operation-filter-drawer
          v-model="filters"
          :bank-list="bankList"
          :currency-list="currencyList"
          :status-list="[statusId]"
          filter-this-month
          @filter="filter"
        />
      </v-col>
    </v-row>

    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <v-skeleton-loader
          type="image"
          height="88px"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <v-skeleton-loader
          type="image"
          height="88px"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <v-skeleton-loader
          type="image"
          height="88px"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <v-skeleton-loader
          type="image"
          height="88px"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <v-skeleton-loader
          type="image"
          height="88px"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <v-skeleton-loader
          type="image"
          height="88px"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <v-skeleton-loader
          type="image"
          height="88px"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <v-skeleton-loader
          type="image"
          height="88px"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <v-skeleton-loader
          type="image"
          height="88px"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <v-skeleton-loader
          type="image"
          height="88px"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <v-skeleton-loader
          type="image"
          height="88px"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <v-skeleton-loader
          type="image"
          height="88px"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-skeleton-loader
          type="image"
          min-height="400"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-skeleton-loader
          type="image"
          min-height="400"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <CardDashboard
          description="Quantidade de operações"
          :value="operations.length"
          icon
          :color="iconType.INTEGER.COLOR"
          :icon-class="iconType.INTEGER.ICON"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <CardDashboard
          description="Clientes ativos"
          :value="activeCustomers.length"
          icon
          :color="iconType.INTEGER.COLOR"
          :icon-class="iconType.INTEGER.ICON"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <CardDashboard
          description="Clientes aprovados"
          :value="approvedCustomers.length"
          icon
          :color="iconType.INTEGER.COLOR"
          :icon-class="iconType.INTEGER.ICON"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <CardDashboard
          description="Clientes totais"
          :value="customerCount"
          icon
          :color="iconType.INTEGER.COLOR"
          :icon-class="iconType.INTEGER.ICON"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <CardDashboard
          description="Spread Médio"
          :value="`${averageSpreadValue || 0}%`"
          icon
          :color="iconType.PERCENT.COLOR"
          :icon-class="iconType.PERCENT.ICON"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <CardDashboard
          description="Total Operado"
          :value="sumSubtotal | formatCurrency"
          icon
          :color="iconType.CURRENCY.COLOR"
          :icon-class="iconType.CURRENCY.ICON"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <CardDashboard
          description="Lucro do Corban"
          :value="sumAgentValue || 0 | formatCurrency()"
          icon
          :color="iconType.CURRENCY.COLOR"
          :icon-class="iconType.CURRENCY.ICON"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <CardDashboard
          description="Lucro do Corban Indicador"
          :value="sumIndicatorValue || 0 | formatCurrency()"
          icon
          :color="iconType.CURRENCY.COLOR"
          :icon-class="iconType.CURRENCY.ICON"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <CardDashboard
          description="Lucro do Corban Master"
          :value="sumCambioHojeComissionValue || 0 | formatCurrency()"
          icon
          :color="iconType.CURRENCY.COLOR"
          :icon-class="iconType.CURRENCY.ICON"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <CardDashboard
          description="Lucro do Banco"
          :value="sumBankComissionValue || 0 | formatCurrency()"
          icon
          :color="iconType.CURRENCY.COLOR"
          :icon-class="iconType.CURRENCY.ICON"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <CardDashboard
          description="Operação média"
          :value="averageOperationValue || 0 | formatCurrency()"
          icon
          :color="iconType.CURRENCY.COLOR"
          :icon-class="iconType.CURRENCY.ICON"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="pa-2"
      >
        <CardDashboard
          description="Lucro bruto"
          :value="sumGrossProfit || 0 | formatCurrency()"
          icon
          :color="iconType.CURRENCY.COLOR"
          :icon-class="iconType.CURRENCY.ICON"
        />
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="6"
        class="pa-2"
      >
        <v-card class="card-cambio card-chart">
          <highcharts :options="valueOperatedPerBankChartOptions" />
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="6"
        class="pa-2"
      >
        <v-card class="card-cambio card-chart">
          <highcharts :options="valueOperatedPerCurrencyChartOptions" />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
//Apis
import DashboardApi from '@/api/generic/dashboard-api';
import CurrencyApi from '@/api/spread/currency-api';
import ExchangeBankApi from '@/api/exchangeBank/exchange-bank-api';

//Models
import OperationFiltersModel from '@/model/operation-filters-model';
import CurrencyModel from '@/model/currency-model';
import ExchangeBankModel from '@/model/exchange-bank-model';

import DashboardOperationModel from '@/model/dashboard/dashboard-operation-model';
import DashboardCustomerExchangeBankModel from '@/model/dashboard/dashboard-customer-exchange-bank-model';
import DashboardCountModel from '@/model/dashboard/dashboard-count-model';

// Structs
import ChartDataStruct from '@/plugins/highcharts/chart-data-struct';

// Utils
import numberUtils from '../../../../common/utils/number';

//Components
import CardDashboard from '@/components/dashboard/CardDashboard.vue';
import OperationFilterDrawer from '@/components/comum/OperationFilterDrawer.vue';

// Constants
import { CUSTOMER_EXCHANGE_BANK_WORKFLOW } from '../../../../common/workflows/customerExchangeBankWorkflow';
import {
  CHART_COLORS,
  ICON_TYPE
} from '@/constants/dashboard-constants';
import { FILTER_TYPE } from '@/constants/filters-constants';
import { OPERATION_WORKFLOW } from '../../../../common/workflows/operationWorkflow';

// Mixins
import mixinMessage from '@/mixin/mixin-message';

export default {
  name: 'Dashboard',
  mixins: [mixinMessage],
  components: {
    CardDashboard,
    OperationFilterDrawer
  },
  inject: ['appData'],
  data() {
    return {
      loading: true,

      filters: new OperationFiltersModel(),

      currencyList: [],
      bankList: [],
      statusId: OPERATION_WORKFLOW.LIQUIDADA.id,

      operations: [],
      exchangeBanksDistinctCustomers: [],
      customerCount: 0,

      iconType: ICON_TYPE,

      selectedDate: FILTER_TYPE.THIS_MONTH,

      valueOperatedPerCurrencyChartOptions: new Object(),
      valueOperatedPerBankChartOptions: new Object(),
      dashboardApi: new DashboardApi(this.appData.currentUser),
      currencyApi: new CurrencyApi(this.appData.currentUser),
      exchangeBankApi: new ExchangeBankApi(this.appData.currentUser),
    };
  },

  watch: {
    '$vuetify.theme.dark': function() {
      this.loadValueOperatedPerCurrencyChart();
      this.loadValueOperatedPerBankChart();
    }
  },
  mounted() {
    Promise.all([
      this.getOperations(),
      this.getCustomerExchangeBanksDistinctCustomerList(),
      this.getCustomerCount(),
      this.getCurrencies(),
      this.getBankList()
    ])
      .then(() => {
        this.loadValueOperatedPerCurrencyChart();
        this.loadValueOperatedPerBankChart();
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    sumSubtotal() {
      return this.operations.reduce((acc, operation) => {
        return acc + (parseFloat(operation.administrative.operationSubTotal) || 0);
      }, 0);
    },
    sumGrossProfit() {
      return this.operations.reduce((acc, operation) => {
        return acc + (parseFloat(operation.administrative.grossProfit) || 0);
      }, 0);
    },
    sumAgentValue() {
      return this.operations.reduce((acc, operation) => {
        return acc + (parseFloat(operation.administrative.agentValue) || 0);
      }, 0);
    },
    sumIndicatorValue() {
      return this.operations.reduce((acc, operation) => {
        return acc + (parseFloat(operation.administrative.indicatorValue) || 0);
      }, 0);
    },
    sumCambioHojeComissionValue() {
      return this.operations.reduce((acc, operation) => {
        return acc + (parseFloat(operation.administrative.cambioHojeComissionValue) || 0);
      }, 0);
    },
    sumBankComissionValue() {
      return this.operations.reduce((acc, operation) => {
        return acc + (parseFloat(operation.administrative.bankComissionValue) || 0);
      }, 0);
    },
    averageSpreadValue() {
      const sumSpreadValue = this.operations.reduce((acc, operation) => {
        return acc + (parseFloat(operation.spread) || 0);
      }, 0);

      const averageSpread = sumSpreadValue / this.operations.length;

      return averageSpread ? averageSpread.toFixed(2) : 0;
    },
    approvedCustomers() {
      return this.exchangeBanksDistinctCustomers.filter(
        (e) => e.status.id === CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_APROVADO.id
      );
    },
    activeCustomers() {
      const customerIdList = this.operations.map(({ customer }) => customer.id);

      return [...new Set(customerIdList)];
    },
    averageOperationValue() {
      const value = this.sumSubtotal / this.operations.length;

      return value ? value.toFixed(2) : 0;
    },
    currencyOperatedValue() {
      return Object.values(
        this.operations.reduce((acc, item) => {
          const id = item.currency.id;
          const operationSubTotal = parseFloat(item.administrative.operationSubTotal);

          if (!acc[id]) {
            acc[id] = { id, operationSubTotal: 0 };
          }

          acc[id].operationSubTotal += operationSubTotal;

          return acc;
        }, {})
      );
    },
    bankOperatedValue() {
      return Object.values(
        this.operations.reduce((acc, item) => {
          const id = item.bank.id;
          const operationSubTotal = parseFloat(item.administrative.operationSubTotal);

          if (!acc[id]) {
            acc[id] = { id, operationSubTotal: 0 };
          }

          acc[id].operationSubTotal += operationSubTotal;

          return acc;
        }, {})
      );
    },
    operatedPerCurrencyChartData() {
      const currencyMap = this.currencyOperatedValue.reduce((map, administrative) => {
        map[administrative.id] = administrative.operationSubTotal;

        return map;
      }, {});

      return this.currencyList.map((c) => {
        const operationSubTotal = currencyMap[c.id] || 0;

        return new ChartDataStruct({
          name: c.name,
          data: [new ChartDataStruct({ y: operationSubTotal, name: 'Total acumulado:' })]
        });
      });
    },
    operatedPerBankChartData() {
      const bankMap = this.bankOperatedValue.reduce((map, cv) => {
        map[cv.id] = cv.operationSubTotal;

        return map;
      }, {});

      return this.bankList.map((c) => {
        const operationSubTotal = bankMap[c.id] || 0;

        return new ChartDataStruct({ y: operationSubTotal, name: c.fantasyName });
      });
    }
  },
  filters: {
    formatCurrency(value, code, precison) {
      code = code || 'BRL';

      return numberUtils.formatCurrency(code, value, precison);
    }
  },
  methods: {
    getOperations() {
      return this.dashboardApi
        .getOperations(this.filters)
        .then(({ data }) => {
          this.operations = data.map((o) => new DashboardOperationModel(o));
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
          }

          this.operations = [];
        });
    },

    getCustomerExchangeBanksDistinctCustomerList() {
      return this.dashboardApi
        .getCustomerExchangeBanksDistinctCustomer(this.filters)
        .then(({ data }) => {
          this.exchangeBanksDistinctCustomers = data.map(
            (c) => new DashboardCustomerExchangeBankModel(c)
          );
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getCustomerCount() {
      return this.dashboardApi
        .getCustomerCount()
        .then(({ data }) => {
          const dashboardCountModel = new DashboardCountModel(data);
          this.customerCount = dashboardCountModel.count;
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getCurrencies() {
      return this.currencyApi
        .findAll()
        .then(({ data }) => {
          this.currencyList = data.map((c) => new CurrencyModel(c));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },
    getBankList() {
      return this.exchangeBankApi
        .findAll()
        .then(({ data }) => {
          this.bankList = data.map((b) => new ExchangeBankModel(b));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    filter() {
      this.loading = true;

      Promise.all([this.getCustomerExchangeBanksDistinctCustomerList(), this.getOperations()])
        .then(() => {
          this.loadValueOperatedPerBankChart();
          this.loadValueOperatedPerCurrencyChart();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    loadValueOperatedPerCurrencyChart() {
      const legendColor = this.$vuetify.theme.dark ? '#FFFFFF' : '#000000';

      const filteredData = this.operatedPerCurrencyChartData.filter(
        (dataPoint) => dataPoint.data[0].y > 0
      );

      this.valueOperatedPerCurrencyChartOptions = {
        chart: {
          type: 'column',
          style: {
            fontFamily: 'Roboto',
          },
        },
        title: {
          text: 'Total acumulado por moeda',
          style: {
            fontFamily: 'Roboto',
            align: 'center',
            fontWeight: 600,
            color: 'gray',
          },
        },
        yAxis: {
          type: 'logarithmic',
          minorTickInterval: 0.1,
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          title: {
            text: null,
          },
          labels: {
            style: {
              color: legendColor,
            },
            format: 'R$ {value:,.2f}',
          },
        },
        xAxis: {
          crosshair: false,
          tickLength: 0,
          labels: {
            enabled: false,
          },
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            showInLegend: true,
            dataLabels: {
              enabled: true,
              rotation: -90,
              color: '#FFFFFF',
              inside: true,
              verticalAlign: 'top',
              pointFormatter: function () {
                return numberUtils.formatCurrency('BRL', this.y);
              },
              y: 10,
              style: {
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          },
        },
        legend: {
          itemStyle: {
            color: legendColor,
          }
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          pointFormatter: function () {
            return `${this.series.name}: ${numberUtils.formatCurrency('BRL', this.y)}`;
          },
        },
        series: filteredData,
        colors: CHART_COLORS,
      };
    },
    loadValueOperatedPerBankChart() {
      const legendColor = this.$vuetify.theme.dark ? '#FFFFFF' : '#000000';
      const filteredData = this.operatedPerBankChartData.filter(dataPoint => dataPoint.y > 0);

      this.valueOperatedPerBankChartOptions = {
        chart: {
          type: 'pie',
          style: {
            fontFamily: 'Roboto'
          }
        },
        title: {
          text: 'Percentual operado por banco',
          style: {
            fontFamily: 'Roboto',
            align: 'center',
            fontWeight: 600,
            color: 'gray'
          }
        },
        legend: {
          itemStyle: {
            color: legendColor,
          }
        },
        credits: {
          enabled: false
        },
        tooltip: {
          pointFormatter: function () {
            return `${this.series.name}: ${numberUtils.formatCurrency('BRL', this.y)}`;
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '{point.percentage:.1f} %',
              distance: -50,
              filter: {
                property: 'percentage',
                operator: '>',
                value: 4
              }
            },
            showInLegend: true
          }
        },
        series: [
          {
            name: 'Total operado',
            data: filteredData,
            colors: CHART_COLORS
          }
        ]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.card-chart {
  padding: 0 16px;
}
::v-deep .highcharts-background {
  fill: transparent;
}
</style>
