<template>
  <v-row no-gutters>
    <v-col>
      <v-row no-gutters>
        <v-col>
          <confirmation-window
            v-model="openConfirmationWindow"
            :callback-primary="callbackConfirmationWindow"
            :message="messageConfirmationWindow"
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <h1>Sócios</h1>
          <v-divider />
        </v-col>
      </v-row>

      <v-row
        no-gutters
        class="py-2"
      >
        <v-col class="d-flex align-center shrink pr-2">
          <v-btn
            class="btn-primary ml-0"
            text
            @click="addPartner"
          >
            <i class="far fa-plus" />
            Adicionar
          </v-btn>
        </v-col>

        <v-col class="d-flex align-center pl-2">
          <v-btn
            class="btn-tertiary ml-0"
            text
            @click="openBindPartnerModal = true"
          >
            <i class="far fa-users" />
            Vincular
          </v-btn>
        </v-col>

        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <v-data-table
            :headers="columns"
            :items="partners"
            :search="search"
            no-data-text="Nenhum sócio cadastrado"
            height="calc(100dvh - 550px)"
            fixed-header
            hide-default-footer
            disable-pagination
            disabled-footer
          >
            <template #[`item.share`]="{ item }">
              <span>{{ `${item.share}%` }}</span>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-row
                no-gutters
                class="d-flex flex-nowrap"
              >
                <v-col>
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        text
                        @click="editPartner(item)"
                        v-bind="attrs"
                        v-on="on"
                        icon
                      >
                        <i class="far fa-pencil" />
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </v-col>

                <v-col>
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        text
                        @click="confirmationRemovePartner(item)"
                        v-bind="attrs"
                        v-on="on"
                        icon
                      >
                        <i class="far fa-trash" />
                      </v-btn>
                    </template>
                    <span>Excluir</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <BaseModal
            v-model="openPartnersModal"
            :title="`Cadastro de sócio - ${companyName || 'Novo cadastro PJ'}`"
            :primary-button-action="savePartner"
            :secondary-button-action="confirmationCancelPartner"
            fullscreen
          >
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-row no-gutters>
                <v-col>
                  <v-row no-gutters>
                    <v-col>
                      <v-radio-group
                        v-model="registerType"
                        row
                        ref="register"
                      >
                        <v-radio
                          v-for="item in registerTypes"
                          :key="item.id"
                          :label="item.description"
                          :value="item.id"
                        />
                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col>
                      <CustomerCover
                        v-model="customer"
                        :is-p-f="!isLegalPerson"
                        is-basic-remitance
                      />

                      <CustomerContact v-model="customer" />

                      <CustomerDocuments
                        v-model="customer"
                        :is-legal-person="isLegalPerson"
                        is-basic-remitance
                      />

                      <CustomerAddress
                        v-model="customer"
                        :is-p-f="!isLegalPerson"
                      />

                      <CustomerPoliticallyExposedPerson v-model="customer" />

                      <v-divider />

                      <CustomerFinancial v-model="customer" />

                      <v-col cols="4">
                        <NumberField
                          v-model="share"
                          label="% de participação"
                          :precisao-decimal="2"
                          :rules="[validationIsRequiredField]"
                          :maxlength="6"
                          right-direction
                        />
                      </v-col>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </BaseModal>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <BaseModal
            v-model="openBindPartnerModal"
            title="Vincular sócio"
            primary-button-text="Vincular"
            :primary-button-action="saveBindPartner"
            :secondary-button-action="cancelBindPartner"
          >
            <v-row no-gutters>
              <v-col>
                <CustomerSearch
                  v-model="customer"
                  :erro-message="messageCustomerRequired"
                />
              </v-col>

              <v-col
                cols="4"
                class="d-flex align-end pl-4"
              >
                <NumberField
                  v-model="share"
                  label="% de participação"
                  :precisao-decimal="2"
                  :maxlength="6"
                  :mensagem-de-erro="messageShareRequired"
                  right-direction
                />
              </v-col>
            </v-row>
          </BaseModal>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// Components
import CustomerCover from "@/components/form-customer/CustomerCover";
import CustomerContact from "@/components/form-customer/CustomerContact";
import CustomerDocuments from "@/components/form-customer/CustomerDocuments.vue";
import CustomerAddress from "@/components/form-customer/CustomerAddress";
import CustomerPoliticallyExposedPerson from "@/components/form-customer/CustomerPoliticallyExposedPerson.vue";
import CustomerFinancial from "@/components/form-customer/CustomerFinancial.vue";
import ConfirmationWindow from "@/components/comum/ConfirmationWindow";
import BaseModal from "@/components/comum/BaseModal.vue";
import CustomerSearch from "@/components/comum/CustomerSearch.vue";

// Models
import CustomerModel from "@/model/customer-model";
import CustomerPartnerShareholderModel from "@/model/customer-partner-shareholder-model";

// Mixin
import mixinNestedFormValidation from "@/mixin/mixin-nested-form-validation";
import mixinValidationRules from "@/mixin/mixin-validation-rules";
import mixinMessage from "@/mixin/mixin-message";

//Apis
import CustomerApi from "@/api/customer/customer-api";
import CustomerDocumentsApi from "@/api/customer/customer-documents-api";

// Constants
import { REGISTER_TYPE } from "@/constants/general-constants.js";
import { CUSTOMER_WORKFLOW } from "../../../../common/workflows/customersWorkflow";
import NumberField from "../comum/NumberField.vue";

export default {
  name: 'CustomerPartners',

  mixins: [
    mixinNestedFormValidation,
    mixinValidationRules,
    mixinMessage
  ],

  model: {
    prop: 'partners',
    event: 'onChange',
  },

  props: {
    partners: { type: Array, default: () => [] },
    companyName: { type: String, default: '' }
  },

  components: {
    CustomerCover,
    CustomerContact,
    CustomerDocuments,
    CustomerAddress,
    CustomerPoliticallyExposedPerson,
    CustomerFinancial,
    ConfirmationWindow,
    BaseModal,
    CustomerSearch,
    NumberField
  },

  inject: ['appData'],

  data() {
    return {
      openPartnersModal: false,
      openBindPartnerModal: false,
      valid: false,
      customer: new CustomerModel(),
      partnerId: undefined,
      share: undefined,
      columns: [
        { text: "CPF/CNPJ ", value: "cpfCnpj", align: "left", sortable: true },
        { text: "Nome/Razão social", value: "name", align: "left", sortable: true },
        { text: "Celular ", value: "cellphone", align: "left", sortable: true },
        { text: "E-mail ", value: "email", align: "left", sortable: true },
        { text: "Participação %", value: "share", align: "right", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false, width: "100px" },
      ],
      search: "",
      registerType: REGISTER_TYPE.BASIC_REMITTANCE.id,
      registerTypes: [REGISTER_TYPE.BASIC_REMITTANCE, REGISTER_TYPE.LEGAL_PERSON],
      customerApi: new CustomerApi(this.appData.currentUser),
      apiCustomersDocuments: new CustomerDocumentsApi(this.appData.currentUser),
      openConfirmationWindow: false,
      callbackConfirmationWindow: () => {},
      messageConfirmationWindow: "",
      messageCustomerRequired: undefined,
      messageShareRequired: undefined
    };
  },

  methods: {
    isFormValid() {
      this.reassignFormInputs(this.$refs.form);
      this.$refs.form.validate();

      if (!this.valid) {
        let invalidElement = this.$refs.form.inputs.find(i => !i.valid);
        invalidElement.$el.scrollIntoView({ behavior: "smooth", block: "end" });

        if (this.isAdministrativeAccess) {
          this.sendMessage("Existem campos obrigatórios não preenchidos", "error");
        }

        return false;
      }

      return true;
    },

    addPartner() {
      this.customer = new CustomerModel();
      this.togglerPartnerModal();
    },

    savePartner() {
      if (!this.isFormValid()) return;

      this.customer.id
        ? this.updatePartner()
        : this.createPartner();
    },

    updatePartner() {
      this.customerApi
        .update(this.customer)
        .then(() => {
          this.updatePartnerOnList(this.customer.id);
          this.togglerPartnerModal();
          this.sendMessage('Sócio atualizado com sucesso', 'success');
        })
        .catch((error) => {
          this.sendMessage(
            error?.response?.data?.mensagem || error,
            "error"
          );
        });
    },

    async createPartner() {
      let customerDocuments = this.customer.documents;

      let customerClone = structuredClone(this.customer);
      customerClone.paymentAccounts = customerClone.paymentAccounts.filter(i => i.bank || i.agency || i.account || i.bankName);
      customerClone.documents = undefined;
      customerClone.registerStatus = CUSTOMER_WORKFLOW.CADASTRO_EM_ANALISE;
      customerClone.registerType = this.registerTypes.find(r => r.id === this.registerType);

      this.$eventBus.$emit('show-loading', true);

      this.customerApi
        .add(customerClone)
        .then((response) => {
          this.saveDocuments(customerDocuments, response.data);

          this.partnerId
            ? this.updatePartnerOnList(response.data.id)
            : this.addPartnerToList(response.data.id);

          this.togglerPartnerModal();
          this.sendMessage('Sócio cadastrado com sucesso', 'success');
        })
        .catch((error) => {
          this.sendMessage(
            error?.response?.data?.mensagem || error,
            "error"
          );
        })
        .finally(() => this.$eventBus.$emit('show-loading', false));
    },

    saveDocuments(customerDocuments, customer) {
      customerDocuments?.forEach((document) => {
        let formData = new FormData();

        formData.append("customerId", customer.id);
        formData.append("idDocumentType", document.documentType.id);
        formData.append("document", document.document);

        this.apiCustomersDocuments.add(formData);
      });
    },

    addPartnerToList(linkedCustomerId) {
      this.localPartners.push(this.getLocalParter(linkedCustomerId));
    },

    updatePartnerOnList(linkedCustomerId) {
      let index = this.localPartners.findIndex(p => p.id === this.partnerId);

      if (index !== -1) this.localPartners.splice(index, 1, this.getLocalParter(linkedCustomerId));
    },

    getLocalParter(linkedCustomerId) {
      return new CustomerPartnerShareholderModel({
        id: this.partnerId,
        cpfCnpj: this.customer.cpfCnpj,
        name: this.customer.name,
        email: this.customer.email,
        cellphone: this.customer.cellphone,
        share: this.share,
        linkedCustomerId: linkedCustomerId
      });
    },

    confirmationCancelPartner() {
      this.messageConfirmationWindow = `Há dados preenchidos, deseja realmente cancelar ${
        this.partnerId ? 'a edição?' : 'o cadastro?'
      }`;

      this.callbackConfirmationWindow = () => {
        this.togglerPartnerModal();
        this.openConfirmationWindow = false;
      };

      this.openConfirmationWindow = true;
    },

    editPartner(partner) {
      this.share = partner.share;
      this.partnerId = partner.id;

      if (partner.linkedCustomerId) {
        this.getCustomerById(partner.linkedCustomerId);

        return;
      }

      this.customer = new CustomerModel(partner);
      this.customer.id = undefined;

      if (partner.isLegalPerson) this.registerType = REGISTER_TYPE.LEGAL_PERSON.id;

      this.togglerPartnerModal();
    },

    confirmationRemovePartner(item) {
      this.messageConfirmationWindow = `Deseja realmente excluir este sócio?`;

      this.callbackConfirmationWindow = () => {
        this.removePartiner(item);
        this.openConfirmationWindow = false;
      };

      this.openConfirmationWindow = true;
    },

    removePartiner(item) {
      let index = this.localPartners.findIndex(p => p.id === item.id);

      if (index !== -1) this.localPartners.splice(index, 1);
    },

    togglerPartnerModal() {
      if (this.openPartnersModal) {
        this.$refs.register?.$el?.scrollIntoView({ behavior: "auto"});
        this.$refs.form.resetValidation();
        this.customer = new CustomerModel();
        this.share = 0;
        this.partnerId = undefined;
      }

      this.openPartnersModal = !this.openPartnersModal;
    },

    async getCustomerById(id) {
      this.$eventBus.$emit('show-loading', true);

      this.customerApi
        .findId(id)
        .then((response) => {
          this.customer = new CustomerModel(response.data);
          this.registerType = this.customer.registerType.id;
          this.togglerPartnerModal();
        })
        .catch((error) => {
          this.sendMessage(
            (error.response?.data?.mensagem) || error,
            "error"
          );
        })
        .finally(() => this.$eventBus.$emit('show-loading', false));
    },

    isValidBindPartner() {
      if (!this.customer.id) this.messageCustomerRequired = 'Este campo é obrigatório';

      if (!this.share) this.messageShareRequired = 'Este campo é obrigatório';

      return (!this.messageCustomerRequired && !this.messageShareRequired);
    },

    saveBindPartner() {
      if (!this.isValidBindPartner()) return;

      this.addPartnerToList(this.customer.id);
      this.customer = new CustomerModel();
      this.share = 0;
      this.openBindPartnerModal = false;
    },

    cancelBindPartner() {
      this.openBindPartnerModal = false;
    },

  },

  computed: {
    localPartners: {
      get() {
        return this.partners;
      },
      set(valor) {
        this.$emit('onChange', valor);
      }
    },

    isLegalPerson() {
      return this.registerType == REGISTER_TYPE.LEGAL_PERSON.id;
    },
  },

  watch: {
    share(value) {
      if (value) this.messageShareRequired = undefined;
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table th,
::v-deep .v-data-table td {
  min-width: 100px;
  max-width: 500px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>