import axios from 'axios';
import utilsStorage from '@/utils/storage';

async function loadConfig() {
  try {
    const response = await fetch('/config.json');

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Erro ao carregar a configuração:', error);

    return {}; // Retorne um objeto vazio ou algum fallback
  }
}

async function login(user) {
  user = user || {};

  let config = await loadConfig();

  return new Promise((resolve, reject) => {
    return axios
      .post(`${config.urlApiAuth}/login`, { email: user.email, password: user.password })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

async function changePassword(password) {
  var token = utilsStorage.getTokenOnStorage();

  let config = await loadConfig();

  return new Promise((resolve, reject) => {
    return axios
      .put(`${config.urlApiAuth}/change-password`, {
        password
      }, { headers: { Authorization: token } })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

async function forgotPassword(email) {
  let config = await loadConfig();

  return new Promise((resolve, reject) => {
    return axios
      .post(`${config.urlApiAuth}/forgot-password`, {
        email
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

async function validateUserByCode(email, code) {
  let config = await loadConfig();

  return new Promise((resolve, reject) => {
    return axios
      .post(`${config.urlApiAuth}/code-login`, {
        email,
        code
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  login,
  changePassword,
  forgotPassword,
  validateUserByCode
};
