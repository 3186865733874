<template>
  <div>
    <span class="expand-title">{{ isInternational ? 'Conta Bancária Internacional' : 'Conta Bancária do Brasil' }}</span>
    <v-card class="box card-cambio my-5">
      <v-row>
        <template v-if="isInternational">
          <v-col cols="12" md="2">
            <span class="expand-title">Banco</span>
            <p class="expand-value">{{ account.bank }}</p>
          </v-col>
          <v-col cols="12" md="2">
            <span class="expand-title">IBAN</span>
            <p class="expand-value">{{ account.numberIBAN }}</p>
          </v-col>
          <v-col cols="12" md="2">
            <span class="expand-title">Código Swift/BIC</span>
            <p class="expand-value">{{ account.swiftCodeBIC }}</p>
          </v-col>
          <v-col cols="12" md="2">
            <span class="expand-title">Número ABA/Routing</span>
            <p class="expand-value">{{ account.tabNumberRouting }}</p>
          </v-col>
          <v-col cols="12" md="2">
            <span class="expand-title">Nome do titular</span>
            <p class="expand-value">{{ account.ownerName }}</p>
          </v-col>
          <v-col cols="12" md="2" v-if="account.forFurtherCredit">
            <span class="expand-title">FFC</span>
            <p class="expand-value">{{ account.forFurtherCredit }}</p>
          </v-col>

          <template v-if="account.intermediary">
            <v-col cols="12" md="2">
              <span class="expand-title">País Intermediário</span>
              <p class="expand-value">{{ account.intermediaryCountry }}</p>
            </v-col>
            <v-col cols="12" md="2">
              <span class="expand-title">Banco Intermediário</span>
              <p class="expand-value">{{ account.intermediaryBankName }}</p>
            </v-col>
            <v-col cols="12" md="2">
              <span class="expand-title">ABA/Routing Intermediário</span>
              <p class="expand-value">{{ account.intermediaryAbaSwiftCode }}</p>
            </v-col>
          </template>
        </template>

        <template v-else>
          <v-col cols="12" md="2">
            <span class="expand-title">Banco</span>
            <p class="expand-value">{{ account.name }}</p>
          </v-col>
          <v-col cols="12" md="2">
            <span class="expand-title">Agência</span>
            <p class="expand-value">{{ account.agency }}</p>
          </v-col>
          <v-col cols="12" md="2">
            <span class="expand-title">Conta</span>
            <p class="expand-value">{{ account.account }}</p>
          </v-col>
          <v-col cols="12" md="2">
            <span class="expand-title">Dígito</span>
            <p class="expand-value">{{ account.digit }}</p>
          </v-col>
        </template>
     </v-row>  
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ContaBancaria',
  props: {
    account: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isInternational: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
</style>
