<template>
  <div class="ma-5">
    <div>
      <ConfirmationWindow
        v-model="openConfirmationWindow"
        :callback-primary="confirmationWindowCallback"
        :message="confimationWindowMessage"
      />
    </div>

    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          type="button"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        cols="12"
        md="12"
        class="pb-0"
      >
        <h1 class="titulo">Fluxo cadastral</h1>
        <v-divider />
      </v-col>
      <v-col
        cols="12"
        lg="3"
        sm="6"
        offset-lg="9"
        offset-sm="6"
        class="py-0"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="12"
        class="pb-0"
      >
        <v-card class="card-cambio pt-1">
          <v-data-table
            :headers="columnsCustomerExchangeBanks"
            :items="customerExchangeBanks"
            :search="search"
            :items-per-page="paginationDefault"
            :expanded.sync="expanded"
            fixed-header
            item-key="id"
            height="calc(100dvh - 310px)"
            sort-by="customer.createdAt"
            sort-desc
            :custom-sort="customSort"
            :custom-filter="customFilter"
            show-expand
            @click:row="clickRow"
          >
            <template #[`item.customer.createdAt`]="{ item }">
              {{ item.customer.createdAt | date }}
            </template>

            <template #[`item.customer.updatedAt`]="{ item }">
              {{ item.customer.updatedAt | date }}
            </template>

            <template #[`item.customer.customerNameOrCompanyName`]="{ item }">
              <span v-if="item.customer.isPJ()"
                >{{ item.customer.companyName }}
                <span v-if="item.customer.fantasyName">
                  <InfoTooltip
                    icon-size="1x"
                    :label="'Nome fantasia: ' + item.customer.fantasyName"
                  />
                </span>
              </span>
              <span v-else>{{ item.customer.name }}</span>
            </template>

            <template #[`item.customer.registerType.description`]="{ item }">
              {{
                registerTypeList.find((i) => i.id == item.customer.registerType.id)?.description ||
                ''
              }}
            </template>

            <template #[`item.bank.companyName`]="{ item }">
              <span
                >{{ item.bank.companyName }}
                <span v-if="item.bank.fantasyName">
                  <InfoTooltip
                    icon-size="1x"
                    :label="'Nome fantasia: ' + item.bank.fantasyName"
                  />
                </span>
              </span>
            </template>

            <template #[`item.statusDescription`]="{ item }">
              <v-chip
                v-if="item.status.id"
                small
                label
                :color="item.status.id | statusColorExchangeBank"
                class="status-description-size"
              >
                {{ item.status.description }}
              </v-chip>
              <v-chip
                v-else
                small
                label
                :color="item.customer.registerStatus.id | statusColorCustomer"
              >
                {{ item.customer.registerStatus.description }}
              </v-chip>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-menu
                offset-y
                left
              >
                <template #activator="{ on: menu, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="menu"
                    v-administrative-access
                  >
                    <i class="far fa-ellipsis-v" />
                  </v-btn>
                </template>

                <v-list>
                  <template
                    v-if="
                      customerWorkflow.CADASTRO_EM_ANDAMENTO.id ==
                        item.customer.registerStatus.id ||
                      customerExchangeBankWorkflow.CADASTRO_EM_ANDAMENTO.id == item.status.id
                    "
                  >
                    <v-list-item @click="updateStatusToFinishedRegister(item)">
                      <v-list-item-title>
                        <i class="far fa-check pr-2 menu-buttons" /> Mudar status p/ Cadastro
                        Finalizado
                      </v-list-item-title>
                    </v-list-item>
                  </template>

                  <template
                    v-if="
                      customerExchangeBankWorkflow.ENVIADO_AO_BANCO.id == item.status.id ||
                      customerExchangeBankWorkflow.PENDECIA_BANCO.id == item.status.id
                    "
                  >
                    <v-list-item @click="updateStatusToPldCompliance(item)">
                      <v-list-item-title>
                        <i class="far fa-repeat pr-2 menu-buttons" /> Mudar status p/ PLD/Compliance
                      </v-list-item-title>
                    </v-list-item>
                  </template>

                  <template
                    v-if="
                      customerExchangeBankWorkflow.ENVIADO_AO_BANCO.id == item.status.id ||
                      customerExchangeBankWorkflow.PLD_COMPLIANCE.id == item.status.id
                    "
                  >
                    <v-list-item @click="updateStatusToBankPendency(item)">
                      <v-list-item-title>
                        <i class="far fa-repeat pr-2 menu-buttons" /> Mudar status p/ Pendência com
                        o banco
                      </v-list-item-title>
                    </v-list-item>
                  </template>

                  <template v-if="customerExchangeBankWorkflow.PLD_COMPLIANCE.id == item.status.id">
                    <v-list-item @click="openAddLimitModal(item)">
                      <v-list-item-title>
                        <i class="far fa-check pr-2 menu-buttons" /> Mudar status p/ Aprovado
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateStatusToRejected(item)">
                      <v-list-item-title>
                        <i class="far fa-ban pr-2 menu-buttons" /> Mudar status p/ Recusado
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateStatusToFinished(item)">
                      <v-list-item-title>
                        <i class="far fa-times pr-3 menu-buttons" /> Mudar status p/ Encerrado
                      </v-list-item-title>
                    </v-list-item>
                  </template>

                  <template v-if="canInactivateCustomer(item.customer)">
                    <v-list-item @click="openInactivateCustomerConfirmation(item)">
                      <v-list-item-title>
                        <i class="far fa-user-slash pr-1 menu-buttons" /> Inativar Cliente
                      </v-list-item-title>
                    </v-list-item>
                  </template>

                  <v-list-item @click="authorizeCustomer(item)">
                    <v-list-item-title>
                      <i class="far fa-eye pr-2 menu-buttons" /> Visualizar
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <template #expanded-item="{ item }">
              <td :colspan="columnsCustomerExchangeBanks.length + 1">
                <v-card class="box card-cambio my-5">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <span class="expand-title">Naturezas</span>
                    </v-col>
                    <v-col v-if="!item.operationNatures.filter((o) => !!o.exibitionName).length">
                      -
                    </v-col>
                    <v-col
                      cols="12"
                      v-for="(nature, i) in item.operationNatures.filter((o) => !!o.exibitionName)"
                      :key="i"
                    >
                      <v-chip
                        small
                        label
                        class="ma-1"
                      >
                        {{ nature.exibitionName }}
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- Add Limit Modal -->
    <v-dialog
      persistent
      scrollable
      eager
      min-width="40vw"
      max-width="100vh"
      v-model="addLimitModal"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Limite aprovado</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                id="add-limit-modal"
                ref="form"
              >
                <v-row>
                  <template v-if="exchangeBank.bank.useInOutLimit">
                    <v-col md="12">
                      <MoneyField
                        label="Valor entrada"
                        :prefix="limitPrefix"
                        v-model="limit.valueIn"
                      />
                    </v-col>
                    <v-col md="12">
                      <MoneyField
                        label="Valor saída"
                        :prefix="limitPrefix"
                        v-model="limit.valueOut"
                      />
                    </v-col>
                  </template>
                  <v-col
                    v-else
                    md="12"
                  >
                    <MoneyField
                      label="Valor"
                      :prefix="limitPrefix"
                      v-model="limit.value"
                    />
                  </v-col>
                  <v-col md="12">
                    <Datepicker
                      v-model="limit.expireAt"
                      label="Data de validade"
                      v-disabled-icon-focus
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primary mr-2"
              @click="addLimit"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="addLimitModal = false"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// Apis
import CustomerApi from '@/api/customer/customer-api';
import CustomerExchangeBanksApi from '@/api/customer/customer-exchange-banks-api';
import TriggerApi from '@/api/generic/trigger-api';

// Models
import CustomerExchangeBankFiltersModel from '@/model/customer/customer-exchange-bank-filters-model';
import FilteredCustomerExchangeBankModel from '@/model/customer/filtered-customer-exchange-bank-model';
import CustomerLimitModel from '@/model/customer-limit-model';
import TriggerModel from '@/model/generic/trigger-model';
import CustomerExchangeBankModel from '@/model/customer-exchange-bank-model';

// Mixins
import mixinMessage from '@/mixin/mixin-message';
import mixinExpandedDataTable from '@/mixin/mixin-data-table-expanded';

// Utils
import dateUtils from '../../../../common/utils/date';
import utilsStorage from '@/utils/storage';

// Tracking
import mixpanel from 'mixpanel-browser';

// Component
import InfoTooltip from '@/components/comum/InfoTooltip';
import MoneyField from '@/components/comum/MoneyField';
import Datepicker from '@/components/comum/Datepicker.vue';
import ConfirmationWindow from '@/components/comum/ConfirmationWindow';

// Constant
import { REGISTER_TYPE } from '../../../../common/constants/generic/types';
import { CUSTOMER_WORKFLOW } from '../../../../common/workflows/customersWorkflow';
import { CUSTOMER_EXCHANGE_BANK_WORKFLOW } from '../../../../common/workflows/customerExchangeBankWorkflow';
import { SEND_EMAIL_TRIGGER_CODES } from '../../../../common/constants/generic/triggers';
import {
  CUSTOMER_EXCHANGE_BANK_STATUS_CORES,
  PAGINATION_DEFAULT,
  COLUMS_CUSTOMER_EXCHANGE_BANKS
} from '@/constants/general-constants';

// utils
import { customSortDataTable, customFilterDataTable } from '@/utils/dataTable';

export default {
  name: 'CustomersAuthorizationControl',
  mixins: [mixinMessage, mixinExpandedDataTable],
  components: {
    InfoTooltip,
    MoneyField,
    Datepicker,
    ConfirmationWindow
  },
  filters: {
    date(value) {
      return dateUtils.maskDateAndHourWithoutSeconds(value);
    },
    statusColorCustomer(statusId) {
      switch (statusId) {
        case CUSTOMER_WORKFLOW.CADASTRO_ENVIADO.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.CADASTRO_ENVIADO;
        case CUSTOMER_WORKFLOW.CADASTRO_EM_ANDAMENTO.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.CADASTRO_ANDAMENTO;
        case CUSTOMER_WORKFLOW.CADASTRO_FINALIZADO.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.CADASTRO_FINALIZADO;
        case CUSTOMER_WORKFLOW.CADASTRO_EM_ANALISE.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.CADASTRO_ANALISE;
        case CUSTOMER_WORKFLOW.PENDECIA_DOCUMENTAL.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.CADASTRO_PENDENCIA_DOCUMENTAL;
        case CUSTOMER_WORKFLOW.ENVIADO_AO_BANCO.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.ENVIADO_AO_BANCO;
        case CUSTOMER_WORKFLOW.CADASTRO_APROVADO.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.CADASTRO_APROVADO;
        case CUSTOMER_WORKFLOW.CADASTRO_RECUSADO.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.CADASTRO_RECUSADO;
        default:
          break;
      }
    },
    statusColorExchangeBank(statusId) {
      switch (statusId) {
        case CUSTOMER_EXCHANGE_BANK_WORKFLOW.ENVIADO_AO_BANCO.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.ENVIADO_AO_BANCO;
        case CUSTOMER_EXCHANGE_BANK_WORKFLOW.PENDECIA_BANCO.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.PENDENCIA_BANCO;
        case CUSTOMER_EXCHANGE_BANK_WORKFLOW.PLD_COMPLIANCE.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.PLD_COMPLIANCE;
        case CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_APROVADO.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.CADASTRO_APROVADO;
        case CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_ENCERRADO.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.CADASTRO_ENCERRADO;
        case CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_RECUSADO.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.CADASTRO_RECUSADO;
        case CUSTOMER_EXCHANGE_BANK_WORKFLOW.ATUALIZACAO_CADASTRAL.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.ATUALIZACAO_CADASTRAL;
        case CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_EM_ANDAMENTO.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.CADASTRO_ANDAMENTO;
        case CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_FINALIZADO.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.CADASTRO_FINALIZADO;
        case CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_EM_ANALISE.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.CADASTRO_ANALISE;
        case CUSTOMER_EXCHANGE_BANK_WORKFLOW.PENDECIA_DOCUMENTAL.id:
          return CUSTOMER_EXCHANGE_BANK_STATUS_CORES.CADASTRO_PENDENCIA_DOCUMENTAL;
        default:
          break;
      }
    }
  },
  inject: ['appData'],
  data() {
    return {
      loading: true,
      search: '',
      user: utilsStorage.getUserOnStorage(),
      customerExchangeBanks: [],
      columnsCustomerExchangeBanks: COLUMS_CUSTOMER_EXCHANGE_BANKS,
      registerTypeList: Object.values(REGISTER_TYPE),
      customerWorkflow: CUSTOMER_WORKFLOW,
      customerExchangeBankWorkflow: CUSTOMER_EXCHANGE_BANK_WORKFLOW,
      limit: new CustomerLimitModel(),
      addLimitModal: false,
      openConfirmationWindow: false,
      confirmationWindowCallback: () => {},
      confimationWindowMessage: '',
      exchangeBank: new CustomerExchangeBankModel(),
      customerApi: new CustomerApi(this.appData.currentUser),
      triggerApi: new TriggerApi(this.appData.currentUser),
      paginationDefault: PAGINATION_DEFAULT,
      customerExchangeBanksApi: new CustomerExchangeBanksApi(this.appData.currentUser),
    };
  },
  mounted() {
    this.getCustomersList();
    mixpanel.track('page_view', {
      name_of_page_viewed: 'customers_authorization_control',
      user_id: this.user.id
    });
  },

  computed: {
    limitPrefix() {
      return this.exchangeBank.bank.useUsdLimit ? 'USD ' : 'R$ ';
    }
  },

  methods: {
    async getCustomersList() {
      this.loading = true;
      this.customerExchangeBanks = [];

      try {
        await this.getCustomersExchangeBankListCustomerInProgress();
        await this.getCustomersExchangeBankListExchangeBankInProgress();
        await this.getCustomersExchangeBankListExchangeBankCompleted();
      } catch (error) {
        
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false; 
        this.customerExchangeBanks.forEach((item, index) => (item.id = index));
      }
    },

    getCustomersExchangeBankListCustomerInProgress() {
      let filters = new CustomerExchangeBankFiltersModel({
        customerRegisterStatusList: [
          CUSTOMER_WORKFLOW.CADASTRO_ENVIADO.id,
          CUSTOMER_WORKFLOW.CADASTRO_EM_ANDAMENTO.id,
          CUSTOMER_WORKFLOW.CADASTRO_FINALIZADO.id,
          CUSTOMER_WORKFLOW.CADASTRO_EM_ANALISE.id,
          CUSTOMER_WORKFLOW.PENDECIA_DOCUMENTAL.id
        ]
      });

      return this.customerExchangeBanksApi
        .find(filters)
        .then((response) => {
          this.customerExchangeBanks = this.customerExchangeBanks.concat(
            response.data.map((c) => new FilteredCustomerExchangeBankModel(c))
          );
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getCustomersExchangeBankListExchangeBankInProgress() {
      let filters = new CustomerExchangeBankFiltersModel({
        customerExchangeBankStatusList: [
          CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_APROVADO.id,
          CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_ENCERRADO.id,
          CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_RECUSADO.id
        ],
        startApprovalDate: new Date().toISOString(),
        endApprovalDate: new Date().toISOString()
      });

      return this.customerExchangeBanksApi
        .find(filters)
        .then((response) => {
          this.customerExchangeBanks = this.customerExchangeBanks.concat(
            response.data.map((c) => new FilteredCustomerExchangeBankModel(c))
          );
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getCustomersExchangeBankListExchangeBankCompleted() {
      let filters = new CustomerExchangeBankFiltersModel({
        customerExchangeBankStatusList: [
          CUSTOMER_EXCHANGE_BANK_WORKFLOW.ENVIADO_AO_BANCO.id,
          CUSTOMER_EXCHANGE_BANK_WORKFLOW.PENDECIA_BANCO.id,
          CUSTOMER_EXCHANGE_BANK_WORKFLOW.PLD_COMPLIANCE.id,
          CUSTOMER_EXCHANGE_BANK_WORKFLOW.ATUALIZACAO_CADASTRAL.id,
          CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_EM_ANDAMENTO.id,
          CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_FINALIZADO.id,
          CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_EM_ANALISE.id,
          CUSTOMER_EXCHANGE_BANK_WORKFLOW.PENDECIA_DOCUMENTAL.id
        ]
      });

      return this.customerExchangeBanksApi
        .find(filters)
        .then((response) => {
          this.customerExchangeBanks = this.customerExchangeBanks.concat(
            response.data.map((c) => new FilteredCustomerExchangeBankModel(c))
          );
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    authorizeCustomer(exchangeBank) {
      mixpanel.track('click', {
        button_name: 'authorize_customer'
      });

      this.updateStatusToAnalysis(exchangeBank);
      this.$router
        .push({
          name: 'FormAuthorizationCustomer',
          params: {
            id: exchangeBank.customer.id
          },
          query: {
            bankId: exchangeBank.bank.id
          }
        })
        .catch(() => {});
    },

    updateStatusToFinishedRegister(exchangeBank) {
      this.$eventBus.$emit('show-loading', true);

      if (exchangeBank.customer.registerStatus.id == CUSTOMER_WORKFLOW.CADASTRO_EM_ANDAMENTO.id) {
        this.updateStatusCustomer(
          exchangeBank.customer,
          CUSTOMER_WORKFLOW.CADASTRO_FINALIZADO
        ).finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
      } else {
        this.updateStatusExchangeBank(
          exchangeBank,
          CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_FINALIZADO.id
        ).finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
      }
    },

    updateStatusToAnalysis(exchangeBank) {
      this.$eventBus.$emit('show-loading', true);

      if (exchangeBank.customer.registerStatus.id == CUSTOMER_WORKFLOW.CADASTRO_FINALIZADO.id) {
        this.customerApi
          .updateStatus(exchangeBank.customer.id, CUSTOMER_WORKFLOW.CADASTRO_EM_ANALISE)
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            this.$eventBus.$emit('show-loading', false);
          });
      } else {
        this.customerExchangeBanksApi
          .updateStatus(
            exchangeBank.customer.id,
            exchangeBank.bank.id,
            CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_EM_ANALISE
          )
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            this.$eventBus.$emit('show-loading', false);
          });
      }
    },

    updateStatusToPldCompliance(exchangeBank) {
      this.$eventBus.$emit('show-loading', true);
      this.updateStatusExchangeBank(
        exchangeBank,
        CUSTOMER_EXCHANGE_BANK_WORKFLOW.PLD_COMPLIANCE
      ).finally(() => {
        this.$eventBus.$emit('show-loading', false);
      });
    },

    updateStatusToBankPendency(exchangeBank) {
      this.$eventBus.$emit('show-loading', true);
      this.updateStatusExchangeBank(
        exchangeBank,
        CUSTOMER_EXCHANGE_BANK_WORKFLOW.PENDECIA_BANCO
      ).finally(() => {
        this.$eventBus.$emit('show-loading', false);
      });
    },

    updateStatusToApproved(exchangeBank) {
      this.$eventBus.$emit('show-loading', true);

      Promise.all([
        this.updateStatusCustomer(exchangeBank.customer, CUSTOMER_WORKFLOW.CADASTRO_APROVADO),
        this.updateStatusExchangeBank(
          exchangeBank,
          CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_APROVADO
        )
      ]).finally(() => {
        this.$eventBus.$emit('show-loading', false);
      });
    },

    updateStatusToRejected(exchangeBank) {
      this.$eventBus.$emit('show-loading', true);

      Promise.all([
        this.updateStatusCustomer(exchangeBank.customer, CUSTOMER_WORKFLOW.CADASTRO_RECUSADO),
        this.updateStatusExchangeBank(
          exchangeBank,
          CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_RECUSADO
        )
      ]).finally(() => {
        this.$eventBus.$emit('show-loading', false);
      });
    },

    updateStatusToFinished(exchangeBank) {
      this.$eventBus.$emit('show-loading', true);
      this.updateStatusExchangeBank(
        exchangeBank,
        CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_ENCERRADO
      ).finally(() => {
        this.$eventBus.$emit('show-loading', false);
      });
    },

    updateStatusToInactivated() {
      this.$eventBus.$emit('show-loading', true);
      this.updateStatusCustomer(
        this.exchangeBank.customer,
        CUSTOMER_WORKFLOW.CADASTRO_INATIVADO
      ).finally(() => {
        this.$eventBus.$emit('show-loading', false);
      });
    },

    updateStatusCustomer(customer, workflow) {
      return this.customerApi
        .updateStatus(customer.id, workflow)
        .then(() => {
          if (workflow.id == CUSTOMER_WORKFLOW.CADASTRO_INATIVADO.id) {
            let index = this.customerExchangeBanks.findIndex((c) => c.customer.id == customer.id);
            this.customerExchangeBanks.splice(index, 1);
            this.openConfirmationWindow = false;

            return;
          }

          let exchangeBankFromList = this.customerExchangeBanks.find(
            (c) => c.customer.id == customer.id
          );
          exchangeBankFromList.customer.registerStatus = workflow;
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    updateStatusExchangeBank(exchangeBank, workflow) {
      let customerId = exchangeBank.customer.id;
      let bankId = exchangeBank.bank.id;

      return this.customerExchangeBanksApi
        .updateStatus(customerId, bankId, workflow)
        .then(() => {
          let exchangeBankFromList = this.customerExchangeBanks.find(
            (ceb) => ceb.customer.id == customerId && ceb.bank.id == bankId
          );
          exchangeBankFromList.status = workflow;
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    openAddLimitModal(exchangeBank) {
      this.exchangeBank = Object.assign({}, exchangeBank);

      this.limit = new CustomerLimitModel();
      let expire = new Date();
      expire.setFullYear(expire.getFullYear() + 1);
      this.limit.expireAt = expire.toISOString();

      this.addLimitModal = true;
    },

    addLimit() {
      this.$eventBus.$emit('show-loading', true);

      if (this.exchangeBank.bank.useInOutLimit) {
        this.limit.remainingIn = this.limit.valueIn;
        this.limit.remainingOut = this.limit.valueOut;
        this.limit.isInOut = true;
      } else {
        this.limit.remaining = this.limit.value;
      }

      this.customerApi
        .addLimit(this.exchangeBank.customer.id, this.exchangeBank.bank.id, this.limit)
        .then(() => {
          this.updateStatusToApproved(this.exchangeBank);
          this.addLimitModal = false;
          this.confirmationWindowCallback = this.callbackSendLimitAproval;
          this.confimationWindowMessage = 'Deseja enviar a aprovação de limite ao cliente?';
          this.openConfirmationWindow = true;
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    },

    callbackSendLimitAproval() {
      this.$eventBus.$emit('show-loading', true);
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.CLIENTE_APROVACAO_LIMITE,
        idCustomer: this.exchangeBank.customer.id,
        idBank: this.exchangeBank.bank.id
      });

      this.triggerApi
        .trigger(trigger)
        .then(() => {
          this.sendMessage('Aprovação de limite enviada com sucesso!', 'success');
          this.openConfirmationWindow = false;
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    },

    openInactivateCustomerConfirmation(exchangeBank) {
      this.exchangeBank = Object.assign({}, exchangeBank);

      this.confirmationWindowCallback = this.updateStatusToInactivated;
      this.confimationWindowMessage = 'Deseja inativar o cliente? Esta ação não pode ser defeita.';
      this.openConfirmationWindow = true;
    },

    customSort(items, sortBy, sortDesc) {
      const customFields = [
        {
          field: 'statusDescription',
          getDescription: (a, b) => {
            return {
              valueA: a.status.id ? a.status.description : a.customer.registerStatus.description,
              valueB: b.status.id ? b.status.description : b.customer.registerStatus.description
            };
          }
        }
      ];

      return customSortDataTable(items, sortBy, sortDesc, customFields);
    },

    customFilter(value, search, item) {
      let customFields = [];

      let computedFields = ['actions', 'customer.customerNameOrCompanyName', 'statusDescription'];

      this.columnsCustomerExchangeBanks
        .filter((col) => !computedFields.includes(col.value))
        .forEach((column) => customFields.push({ name: column.value }));

      customFields.push({
        name: 'customer.customerNameOrCompanyName',
        value: (item) => (item.customer.isPJ() ? item.customer.companyName : item.customer.name)
      });

      customFields.push({
        name: 'customer.statusDescription',
        value: (item) =>
          item.status.id ? item.status.description : item.customer.registerStatus.description
      });

      return customFilterDataTable(value, search, item, customFields);
    },

    canInactivateCustomer(customer) {
      const allowedStatus = [
        CUSTOMER_WORKFLOW.CADASTRO_ENVIADO.id,
        CUSTOMER_WORKFLOW.CADASTRO_EM_ANDAMENTO.id,
        CUSTOMER_WORKFLOW.CADASTRO_FINALIZADO.id,
        CUSTOMER_WORKFLOW.CADASTRO_EM_ANALISE.id,
        CUSTOMER_WORKFLOW.PENDECIA_DOCUMENTAL.id
      ];

      return allowedStatus.includes(customer.registerStatus.id);
    }
  }
};
</script>

<style scoped>
.menu-buttons {
  color: var(--v-primary-base);
}

::v-deep .email-column {
  word-break: break-all;
}
</style>
