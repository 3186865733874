<template>
  <v-app :id="id.replace(/[^a-z0-9]/gi, '-').toLowerCase() + '-app'">
    <v-card class="card-cambio">
      <v-flex
        xs12
        sm12
        md12
      >
        <editor-tinymce
          v-model="textoDoEditor"
          :id="id.replace(/[^a-z0-9]/gi, '-').toLowerCase()"
          @onFocusIn="focusIn"
          @onFocusOut="focusOut"
          :init="init"
          :initial-value="inicial"
          :disabled="desabilitarEditor"
          :required="campoObrigatorio"
        />
      </v-flex>
    </v-card>
  </v-app>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import tinymce from "tinymce/tinymce";
// import tinymce from 'tinymce/tinymce'
// Plugins
import "tinymce/plugins/advlist";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autosave";
import "tinymce/plugins/bbcode";
import "tinymce/plugins/codesample";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/charmap";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/code";
import "tinymce/plugins/fullpage";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/paste";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/template";
import "tinymce/plugins/image";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/help";
import "tinymce/plugins/anchor";
import "@/plugins/editor-tinymce/svg-material-icons-editor.js";
import variaveisEditor from "@/plugins/editor-tinymce/mount-variable-editor.js";

export default {
  model: {
    prop: "entrada",
    event: "onChange",
  },
  props: {
    inicial: {
      type: String,
    },
    entrada: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: "editor-tinemce",
    },
    editorToolbar: {
      type: String,
      default: `inserirdados undo redo pagebreak formatselect 
              fontselect fontsizeselect link bold italic underline 
              alignleft aligncenter alignright 
              alignjustify outdent indent numlist bullist 
              forecolor backcolor removeformat table image  anchor codesample `,
    },
    templatesDoEditor: {
      type: Array,
      default: () => [],
    },
    variaveisDoEditor: {
      type: Object,
      default: () => { },
    },
    alturaDoEditor: {
      type: Number,
      default: 550,
    },
    desabilitarEditor: {
      type: Boolean,
      default: false,
    },
    campoObrigatorio: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    //Isso forca que as variaveis sejam carregadas antes da inicializacao do componentes em si
    // Foi necessario pois as callbacks do vue nao estavam sendo o suficiente. O editor estava sendo carregado
    // antes mesmo do request terminar.
    "editor-tinymce": (resolve) => {
      variaveisEditor.init().then(() => resolve(Editor));
    },
  },
  watch: {
    entrada(novoValor) {
      this.setNovoValorNoEditor(novoValor);
    },

    textoDoEditor(valor) {
      this.change(valor);
    },
  },

  data() {
    return {
      textoDoEditor: "",
      init: {
        extended_valid_elements: "span[custom-variable|style|contenteditable]",
        selector: "textarea",
        theme: "silver",
        theme_url: window.location.origin+ "/themes/silver/theme.js",
        icons: "material",
        icons_url: window.location.origin + "/icons.js",
        base_url: window.location.origin,
        height: this.alturaDoEditor,
        branding: false,
        convert_urls: false,  // Impede que o TinyMCE converta URLs
        relative_urls: false, 
        external_plugins: {
          inserirdados: "",
          "inserirdados-auto-complete": "",
        },
        plugins: [
          "print advlist autolink lists link image charmap print preview",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code wordcount pagebreak anchor codesample",
        ],
        menubar: 'file edit view insert format tools table tc help',
        contextmenu: false,
        automatic_uploads: true,
        a11y_advanced_options: true,
        file_picker_types: "image",
        /* and here's our custom image picker*/
        file_picker_callback: function (cb) {
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");

          input.onchange = function () {
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function () {
              /*
          Note: Now we need to register the blob in TinyMCEs image blob
          registry. In the next release this part hopefully won't be
          necessary, as we are looking to handle it internally.
        */
              var id = "blobid" + new Date().getTime();
              var blobCache = tinymce.activeEditor.editorUpload.blobCache;
              var base64 = reader.result.split(",")[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              /* call the callback and populate the Title field with the file name */
              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
        // contextmenu: "copy | inserttable  cell row column deletetable",
        toolbar: `${this.editorToolbar}`,
        templates: this.templatesDoEditor,
        template_replace_values: this.variaveisDoEditor,
        language: "pt_BR",
        language_url: window.location.origin + "/langs/pt_BR.js",
        content_style: "@media print{@page{size:auto;margin:0;}}",
        skin: window.localStorage.isDark == 'true'
          ? "oxide-dark"
          : "oxide",
        content_css: window.localStorage.isDark == 'true'
          ? "dark"
          : "default"
      },
    };
  },

  methods: {
    //TODO: Fiz desta forma pois nao consegui de outra forma acessar o compomente via CSS.
    //O tinymce nao cria o editor dentro do textarea, ele cria um iframe fora do contexto.
    //futuramente com mais calma, encontrar uma forma de  alterar o focus de maneira mais elegante.
    focusIn() {
      document
        .querySelector("div.tox-sidebar-wrap > .tox-edit-area")
        .classList.add("focus");
      document
        .querySelector(".tox-tinymce > div.tox-statusbar")
        .classList.add("focus");
    },

    focusOut() {
      document
        .querySelector("div.tox-sidebar-wrap > .tox-edit-area")
        .classList.remove("focus");
      document
        .querySelector(".tox-tinymce > div.tox-statusbar")
        .classList.remove("focus");
    },

    setNovoValorNoEditor(novoValor) {
      this.textoDoEditor = novoValor || "";
    },

    change(novoValor) {
      this.$emit("onChange", novoValor ? novoValor : undefined);
    },
  },
};
</script>

<style lang="scss" >
%borda-esquerda-direita-defaul {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

%ajustes-divs-internas-toolbar {
  background-color: #fff;
  box-shadow: 0 0 12px 0 rgba(50, 50, 50, 0.1);
  padding: 5px;
}

.tox.tox-tinymce {
  border: none;
}

div.tox.tox-tinymce {
  border-left: none;
  border-right: none;
}

.tox-toolbar {
  border: none;
}

.tox-toolbar .tox-toolbar__group:first-child {
  border: none;
  @extend %ajustes-divs-internas-toolbar;
}

div .tox .tox-toolbar {
  margin: 10px 2px 10px 2px;
  border: none;
  background: none;
  background-color: #fff;
  width: 100%;
  padding-right: 4px;
}

div.tox-statusbar {
  @extend %borda-esquerda-direita-defaul;
}

div.tox .tox-toolbar__primary {
  background-image: none;
}

div.tox-sidebar-wrap>.tox-edit-area {
  box-shadow: 0 0 12px 0 rgba(50, 50, 50, 0);
  margin-left: 2px;
  margin-right: 2px;
  border: 1px solid #e8e8e8;
}

div.tox-sidebar-wrap>.tox-edit-area.focus {
  border: 1.5px solid var(--v-primary-base);
  margin-left: 2px;
  margin-right: 2px;
}

.tox-tinymce>div.tox-statusbar {
  box-shadow: 0 0 12px 0 rgba(50, 50, 50, 0);
  border: 1.5px solid #e8e8e8;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 4px;
  border-top: none;
}

.tox-tinymce>div.tox-statusbar.focus {
  border: 1.5px solid var(--v-primary-base);

  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 4px;
  border-top: none;
}

div.tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
  border-right: none;
}

.tox-menu.tox-collection.tox-collection--list {
  border-top: 2px solid var(--v-primary-base);
}

.tox-statusbar>.tox-statusbar__text-container>.tox-statusbar__path {
  display: none;
}

.tox .tox-autocompleter .tox-autocompleter-highlight {
  background: var(--v-primary-base);
  color: #000;
}

.tox button.tox-button--secondary,
.tox button.tox-button--secondary:hover:not(:disabled) {
  color: var(--v-primary-base);
}

div.tox-dialog__footer-end>button.tox-button:not(.tox-button--secondary),
.tox button.tox-button:active:not(:disabled) {
  background-color: var(--v-primary-base);
  border-color: var(--v-primary-base);
}

div.tox-tinymce>div.tox-statusbar,
div>div.tox.tox-tinymce {
  border-radius: 5px;
}


div.tox-sidebar-wrap>.tox-edit-area.focus {
  border: 2px solid var(--v-primary-base);
  border-radius: 5px;
}

.tox button.tox-statusbar__wordcount {
  padding-bottom: 10px;
}

div.tox-tinymce>div.tox-statusbar {
  padding-top: 15px;
  padding-bottom: 5px;
}

@media only screen and (max-width: 1263px) {
  .tox-toolbar .tox-toolbar__group:first-child {
    margin-right: 5px;
  }
}

::v-deep body#tinymce {
  background-color: red;
}

div.tox-collection__item-label,
div.tox :not(svg):not(rect) {
  font-family: 'Rubik', sans-serif;
}
</style>
