<template>
  <div>
    <v-row class="py-2">
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <v-autocomplete
          label="Natureza da Operação"
          :items="operationNatureList"
          item-text="exibitionName"
          item-value="id"
          v-model="customer.additional.operationNature"
          return-object
          clearable
          dense
          outlined
          hint="Este campo é obrigatório"
          v-disabled-icon-focus
          :rules="[validationIsRequiredAutocompleteId]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <CpfOrCnpjField
          is-p-j
          v-model="customer.cpfCnpj"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-text-field
          outlined
          dense
          label="Razão Social"
          v-model="customer.companyName"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-text-field
          outlined
          dense
          label="Nome Fantasia"
          v-model="customer.fantasyName"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <v-autocomplete
          label="Forma de constituição"
          :items="listOfFormOfIncorporation"
          item-text="description"
          v-model="customer.additional.formOfIncorporation"
          return-object
          clearable
          dense
          outlined
          hint="Este campo é obrigatório"
          v-disabled-icon-focus
          :rules="[validationIsRequiredAutocompleteId]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <v-text-field
          outlined
          dense
          label="Setor de atividade"
          v-model="customer.additional.sectorActivity"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-text-field
          outlined
          dense
          label="Atividade principal"
          v-model="customer.additional.mainActivity"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="5"
      >
        <CNAEField
          label="CNAE"
          v-model="customer.additional.idCNAE"
          :rules="[validationIsRequiredAutocompleteText]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <v-text-field
          outlined
          dense
          label="Qnt. de funcionarios"
          v-model="customer.additional.numberEmployees"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <MoneyField
          label="Faturamento bruto anual"
          v-model="customer.additional.annualGrossRevenue"
          :rules="[validationIsRequiredFieldNumeric]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <MoneyField
          label="Capital social integralizado"
          v-model="customer.additional.integratedSocialCapital"
          :rules="[validationIsRequiredFieldNumeric]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-autocomplete
          label="Patrimônio"
          :items="listOfAssets"
          hide-selected
          v-model="customer.additional.assets"
          clearable
          dense
          outlined
          item-text="description"
          item-value="id"
          v-disabled-icon-focus
          :rules="[validationIsRequiredAutocompleteText]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <Datepicker
          v-model="customer.additional.foundingDate"
          label="Data da fundação"
          v-disabled-icon-focus
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <Datepicker
          v-model="customer.additional.lastChangeDate"
          label="Data da última alteração"
          v-disabled-icon-focus
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
        v-if="hasBoardElectionDate"
      >
        <Datepicker
          v-model="customer.additional.boardElectionDate"
          label="Data da eleição da diretoria"
          v-disabled-icon-focus
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-autocomplete
          label="Corban"
          :items="correspondentsList"
          v-model="customer.correspondent"
          item-value="id"
          item-text="name"
          return-object
          dense
          clearable
          outlined
          v-disabled-icon-focus
          v-if="isAdministrativeAccess"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Constants
import { FORM_OF_INCOPORATION, ASSETS } from "@/constants/general-constants.js";

// Components
import CpfOrCnpjField from "@/components/comum/CpfOrCnpjField.vue";
import CNAEField from "@/components/comum/CNAEField.vue";
import Datepicker from "@/components/comum/Datepicker.vue";
import MoneyField from "@/components/comum/MoneyField";

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";
import mixinAuthorization from "@/mixin/mixin-authorization";

// Api
import OperationNatureApi from "@/api/configuration/operation-nature-api";
import CorbanApi from "@/api/correspondent/correspondent-api";

// Models
import OperationNatureModel from "@/model/operation-nature-model";
import CorrespondentModel from "@/model/correspondent-model";
import mixinMessage from "@/mixin/mixin-message";

export default {
  name: "PJCustomerCover",
  mixins: [ mixinValidationRules, mixinAuthorization, mixinMessage ],
  components: {
    CpfOrCnpjField,
    CNAEField,
    Datepicker,
    MoneyField,
  },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  inject: ['appData'],
  data() {
    return {
      required: [(v) => !!v || `Este campo é obrigatório`],
      customer: this.customerProp,
      operationNatureList: [],
      listOfFormOfIncorporation: FORM_OF_INCOPORATION,
      correspondentsList: [],
      listOfAssets: ASSETS,
      corbanApi: new CorbanApi(this.appData.currentUser),
      operationNatureApi: new OperationNatureApi(this.appData.currentUser),
    };
  },
  computed: {
    hasBoardElectionDate() {
      return !this.customer ||
        this.customer.additional.formOfIncorporation.id != 5
        ? false
        : true;
    },
  },
  mounted() {
    this.getOperationNatureList();
    this.getCorrespondentsList();
  },
  methods: {
    getOperationNatureList() {
      this.operationNatureApi
        .findAll()
        .then((response) => {
          this.operationNatureList = response.data.map((n) => new OperationNatureModel(n));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getCorrespondentsList() {
      this.corbanApi
        .findAll()
        .then((response) => {
          this.correspondentsList = response.data.map((c) => new CorrespondentModel(c));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  }
};
</script>

<style>
.form-label {
  font-weight: 400;
}
</style>